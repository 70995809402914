import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

const FullForumPage = () => {
  const { forumId } = useParams(); // Extract forumId from the URL
  const [forums, setForums] = useState([]);
  const [forum, setForum] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [selectedTable, setSelectedTable] = useState("forums"); // Default table name

  // Fetch forums based on the selected table
  useEffect(() => {
    const fetchForums = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase.from(selectedTable).select('*');
        if (error) {
          setError(`Failed to fetch forums from ${selectedTable}`);
          console.error(error);
        } else {
          setForums(data);
        }
      } catch (err) {
        setError('An unexpected error occurred');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchForums();
  }, [selectedTable]);

  // Fetch a single forum if a forumId is provided
  useEffect(() => {
    if (forumId) {
      const fetchForum = async () => {
        setLoading(true);
        try {
          const { data, error } = await supabase
            .from(selectedTable)
            .select('*')
            .eq('id', forumId)
            .single();

          if (error) {
            setError(`Failed to fetch forum from ${selectedTable}`);
            console.error(error);
          } else {
            setForum(data);
          }
        } catch (err) {
          setError('An unexpected error occurred');
          console.error(err);
        } finally {
          setLoading(false);
        }
      };

      fetchForum();
    }
  }, [forumId, selectedTable]);

  // Filter forums based on search query
  const filteredForums = forums.filter(
    (forum) =>
      forum.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      forum.description?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Render the content, replacing any image URLs with <img> elements
  const renderContentWithImages = (content) => {
    const imageRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/gi;
    const parts = content.split(imageRegex);

    return parts.map((part, index) => {
      if (imageRegex.test(part)) {
        return (
          <img
            key={index}
            src={part}
            alt="Embedded content"
            className="my-4 min-w-max-10px max-h-26 rounded-lg shadow-md"
          />
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  // Default Page UI: List of all forums and search functionality
  if (!forumId) {
    return (
      <div className="min-h-screen p-8 bg-gray-100">
        {/* Navigation Bar */}
        <nav className="flex justify-between items-center bg-blue-500 p-4 rounded-lg ml-0 mb-6">
          <Link to="/" className="text-white text-xl font-bold">Home</Link>
          <Link to="/forum" className="text-white text-xl">Forums</Link>
        </nav>

        {/* Dropdown for Table Selection */}
        <div className="mb-4">
          <label htmlFor="tableSelect" className="block text-gray-700 font-semibold mb-2">
            Select Category:
          </label>
          <select
            id="tableSelect"
            value={selectedTable}
            onChange={(e) => setSelectedTable(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
          >
            <option value="forums">Forums</option>
            <option value="official">Official</option>
            {/* Add more table options as needed */}
          </select>
        </div>

        {/* Search Bar */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search forums..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Display List of Forums */}
        {loading ? (
          <div className="text-xl font-semibold text-gray-700">Loading...</div>
        ) : error ? (
          <div className="text-red-500 font-semibold">{error}</div>
        ) : (
          <div>
            {filteredForums.length > 0 ? (
              filteredForums.map((forum) => (
                <div key={forum.id} className="bg-white p-4 mb-4 rounded-lg shadow-md">
                  <h2 className="text-xl font-semibold text-gray-800">
                    <Link to={`/forum/${forum.id}`} className="hover:text-blue-500">
                      {forum.title}
                    </Link>
                  </h2>
                  <p className="mt-2 text-gray-600">{forum.description}</p>
                </div>
              ))
            ) : (
              <div>No forums found</div>
            )}
          </div>
        )}
      </div>
    );
  }

  // If a forumId is present, show the individual forum page
  if (loading) {
    return <div className="text-xl font-semibold text-gray-700">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 font-semibold">{error}</div>;
  }

  if (forum === null) {
    return <div className="text-red-500 font-semibold">Forum not found</div>;
  }

  return (
    <div className="min-h-screen p-4 bg-gray-100">
      {/* Navigation Bar */}
      <nav className="flex justify-between items-center bg-blue-500 p-4 rounded-lg mb-4 ml-0 min-w-full">
        <Link to="/" className="text-white text-xl font-bold">Home</Link>
        <Link to="/forum" className="text-white text-xl">Forums</Link>
      </nav>

      {/* Forum Content */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-semibold text-gray-800">{forum.title}</h1>
        <p className="mt-2 text-gray-600 text-lg">{forum.description}</p>
        <div className="mt-6 text-gray-700">
          {renderContentWithImages(forum.content)}
        </div>
      </div>
    </div>
  );
};

export default FullForumPage;
