import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';
import UseMediaQuery from './useMediaQuery';

const PublicPage = () => {
  const [forums, setForums] = useState([]);
  const [officialForums, setOfficialForums] = useState([]);  // State to hold official forums
  const [filteredForums, setFilteredForums] = useState([]);
  const [savedForums, setSavedForums] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSaved, setShowSaved] = useState(false);
  const [showOfficialUpdates, setShowOfficialUpdates] = useState(false);

  const isMobile = UseMediaQuery('(max-width: 768px)');

  // Filter forums based on search query
  const filterForums = useCallback((query) => {
    if (!query) {
      setFilteredForums(forums);
      return;
    }

    const lowercaseQuery = query.toLowerCase();
    const relevanceSortedForums = forums
      .filter((forum) =>
        forum.title.toLowerCase().includes(lowercaseQuery) ||
        forum.content.toLowerCase().includes(lowercaseQuery)
      )
      .sort((a, b) => {
        const aTitleMatch = a.title.toLowerCase().includes(lowercaseQuery);
        const bTitleMatch = b.title.toLowerCase().includes(lowercaseQuery);
        if (aTitleMatch && !bTitleMatch) return -1;
        if (!aTitleMatch && bTitleMatch) return 1;
        return 0;
      });

    setFilteredForums(relevanceSortedForums);
  }, [forums]);

  // Fetch both forums and official forums
  useEffect(() => {
    const fetchForums = async () => {
      const { data: officialData, error: officialError } = await supabase.from('official').select('*');
      if (officialError) {
        console.error('Error fetching official forums:', officialError);
      } else {
        setOfficialForums(officialData);  // Set the fetched official forums
      }

      const { data, error } = await supabase.from('forums').select('*');
      if (error) {
        console.error('Error fetching popular forums:', error);
      } else {
        setForums(data);  // Set the fetched popular forums
      }
    };

    fetchForums();
  }, []);

  // Apply filtering whenever search query changes
  useEffect(() => {
    filterForums(searchQuery);
  }, [searchQuery, filterForums]);

  // Save forum to localStorage
  const saveForum = (forum) => {
    const updatedSavedForums = [...savedForums, forum];
    localStorage.setItem('savedForums', JSON.stringify(updatedSavedForums));
    setSavedForums(updatedSavedForums);
  };

  // Remove saved forum from localStorage
  const removeSavedForum = (forumId) => {
    const updatedSavedForums = savedForums.filter((forum) => forum.id !== forumId);
    localStorage.setItem('savedForums', JSON.stringify(updatedSavedForums));
    setSavedForums(updatedSavedForums);
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-50">
      <aside className="w-full lg:w-44 p-4 bg-gray-800 text-white">
        <ul className="mt-6">
          <li className="mb-4">
            <button
              className="w-full px-4 py-2 text-left rounded-lg bg-gray-700 hover:bg-blue-600 transition"
              onClick={() => {
                setShowOfficialUpdates(true);  // Show official updates
                setShowSaved(false);
              }}
            >
              Official
            </button>
          </li>
          <li className="mb-4">
            <button
              className="w-full px-4 py-2 text-left rounded-lg bg-gray-700 hover:bg-blue-600 transition"
              onClick={() => {
                setShowSaved(false);
                setShowOfficialUpdates(false);  // Show popular forums
              }}
            >
              Popular Forums
            </button>
          </li>
          <li className="mb-4">
            <button
              className="w-full px-4 py-2 text-left rounded-lg bg-gray-700 hover:bg-blue-600 transition"
              onClick={() => {
                setShowSaved(true);  // Show saved forums
                setShowOfficialUpdates(false);
              }}
            >
              Saved Forums
            </button>
          </li>
          <li className="mb-4">
          
              <Link to={`/forum`}>
              <button className="w-full px-4 py-2 text-left rounded-lg bg-gray-700 hover:bg-blue-600 transition"> Forums </button>
              </Link>
          </li>
        </ul>
      </aside>

      <main className="flex-1 p-4 lg:p-8">
        <h1 className="text-2xl font-semibold text-gray-800 mb-6">
          {showSaved ? 'Saved Forums' : showOfficialUpdates ? 'Official Updates' : 'Popular Forums'}
        </h1>

        {/* Search Input */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search forums..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Official Updates Section */}
        {showOfficialUpdates && (
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">Official Updates</h2>
            <ul className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {officialForums.map((forum) => (
                <li key={forum.id} className="p-6 bg-white border border-gray-300 rounded-lg shadow-md transition hover:shadow-lg">
                  <h2 className="mb-2 text-lg font-bold">{forum.title}</h2>
                  <div className="text-gray-700">
                    {forum.content.slice(0, 200)}{isMobile ? '...' : ''}
                  </div>
                  <Link
                    to={`/official/${forum.id}`}  /* Update link to /official */
                    className="text-blue-600 hover:underline mt-2 inline-block mr-4 decoration-transparent"
                  >
                    Read More
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Popular Forums Section */}
        {!showOfficialUpdates && (
          <div className="mb-6">
            {!showSaved ? (
                  <h2 className="text-xl font-semibold mb-4">Popular Forums</h2>
                  ) : (
                  <h2 className="text-xl font-semibold mb-4">Saved Forums</h2>
                  )}
            <ul className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {(showSaved ? savedForums : filteredForums).map((forum) => (
                <li key={forum.id} className="p-6 bg-white border border-gray-300 rounded-lg shadow-md transition hover:shadow-lg">
                  <h2 className="mb-2 text-lg font-bold">{forum.title}</h2>
                  <div className="text-gray-700">
                    {forum.content.slice(0, 200)}{isMobile ? '...' : ''}
                  </div>
                  <Link
                    to={`/forum/${forum.id}`}
                    className="text-blue-600 hover:underline mt-2 inline-block mr-4 decoration-transparent"
                  >
                    Read More
                  </Link>
                  {!showSaved ? (
                    <button className="mt-4 text-blue-600 hover:underline decoration-transparent" onClick={() => saveForum(forum)}>
                      Save
                    </button>
                  ) : (
                    <button
                      className="mt-4 text-red-600 hover:underline decoration-transparent"
                      onClick={() => removeSavedForum(forum.id)}
                    >
                      Remove
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </main>
    </div>
  );
};

export default PublicPage;
