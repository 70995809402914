import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import PublicPage from './PublicPage';
import AdminPage from './AdminPage';
import { supabase } from './supabaseClient';
import useMediaQuery from './useMediaQuery'; // Assuming you have the custom hook defined in the same directory
import FullForumPage from './FullForumPage';
import CreateForumPage from './CreateForumPage';
import OfficialForumPage from './OfficialForumPage';
import logo from './Logo.png';

const App = () => {
  const isMobile = useMediaQuery('(max-width: 768px)'); // Use custom media query hook for responsiveness

  return (
    <Router>
      <div className="max-h-full max-w-full flex flex-col bg-slate-800">
        {/* Top Navbar */}
        <nav className={`w-full ${isMobile ? 'bg-black' : 'bg-black'} text-white shadow-md max-h-13 border-white border-4 border-solid rounded-3xl mb-2 mt-2`}>
          <div className="flex justify-between items-center px-6 py-2"> {/* Adjusted padding */}
            <Link to="/" className="text-xl font-bold flex items-center">
              <img src={isMobile ? './logo.png' : `${logo}`} className='h-8' alt='logo' /> {/* Adjusted logo height */}
            </Link>
              <h1> EVTECH FORUMS V1 </h1>
            <Link
              to="/login"
              className="px-4 py-1 bg-blue-700 rounded-md hover:bg-blue-500 text-center"
            >
              LOGIN
            </Link>
          </div>
        </nav>

        {/* Main Content */}
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<PublicPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forum" element={<FullForumPage />} />
            <Route path="/official/" element={<OfficialForumPage />} />
            <Route path="/official/:forumId" element={<OfficialForumPage />} />
            <Route path="/forum/:forumId" element={<FullForumPage />} />
            <Route path="/create-forum" element={<CreateForumPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

// Admin Login Page
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // React Router v6 hook for programmatic navigation

  const handleLogin = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      setError('Invalid login credentials');
    } else {
      navigate('/admin'); // Navigate to the admin page on successful login
    }
  };

  return (
    <div className="flex items-center justify-center min-h-full bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-center text-blue-600">Admin Login</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 mt-4 font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default App;