import React, { useState } from 'react';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';

const CreateForumPage = () => {
  const [newForum, setNewForum] = useState({ title: '', content: '' });
  const [selectedTable, setSelectedTable] = useState('forums'); // Default table
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForumSubmit = async (e) => {
    e.preventDefault();
    if (!newForum.title || !newForum.content) return;

    setLoading(true);

    const { error } = await supabase.from(selectedTable).insert([newForum]);

    setLoading(false);

    if (error) {
      console.error(error);
    } else {
      navigate('/admin');
    }
  };

  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <h1 className="text-2xl font-semibold text-gray-800">Create New Forum</h1>

      {/* Table Selector */}
      <div className="mt-6">
        <label className="block text-gray-700 font-semibold">Select Table:</label>
        <select
          value={selectedTable}
          onChange={(e) => setSelectedTable(e.target.value)}
          className="mt-2 px-4 py-2 border rounded-lg"
        >
          <option value="forums">forums</option>
          <option value="official">official</option>
        </select>
      </div>

      {/* Create New Forum Form */}
      <form onSubmit={handleForumSubmit} className="mt-6 p-6 bg-white border rounded-lg shadow-md">
        <input
          type="text"
          placeholder="Forum Title"
          value={newForum.title}
          onChange={(e) => setNewForum({ ...newForum, title: e.target.value })}
          className="mt-4 w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <textarea
          placeholder="Forum Content"
          value={newForum.content}
          onChange={(e) => setNewForum({ ...newForum, content: e.target.value })}
          className="mt-4 w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? 'Creating Forum...' : 'Create Forum'}
        </button>
      </form>
    </div>
  );
};

export default CreateForumPage;
