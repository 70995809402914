// src/OfficialForumPage.js
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';

const OfficialForumPage = () => {
  const { forumId } = useParams(); // Extract forumId from the URL
  const navigate = useNavigate(); // Hook to handle programmatic navigation
  const [forum, setForum] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Redirect to /forum if no forumId is present
  useEffect(() => {
    if (!forumId) {
      // Redirect to the /forum route if forumId is missing
      navigate('/forum');
    }
  }, [forumId, navigate]);

  useEffect(() => {
    const fetchForum = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('official')
          .select('*')
          .eq('id', forumId)
          .single();  // Fetch the forum by ID

        if (error) {
          setError('Failed to fetch forum');
          console.error(error);
        } else {
          setForum(data); // Set the forum data
        }
      } catch (err) {
        setError('An unexpected error occurred');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (forumId) { // Only fetch forum if forumId exists
      fetchForum();
    }
  }, [forumId]);

  // Render content with embedded images
  const renderContentWithImages = (content) => {
    const imageRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/gi;
    const parts = content.split(imageRegex);

    return parts.map((part, index) => {
      if (imageRegex.test(part)) {
        return (
          <img
            key={index}
            src={part}
            alt="Embedded content"
            className="my-4 min-w-max-10px max-h-26 rounded-lg shadow-md"
          />
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  if (loading) {
    return <div className="text-xl font-semibold text-gray-700">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 font-semibold">{error}</div>;
  }

  if (forum === null) {
    return <div className="text-red-500 font-semibold">Forum not found</div>;
  }

  return (
    <div className="min-h-screen p-4 bg-gray-100">
      {/* Navigation Bar */}
      <nav className="flex justify-between items-center bg-blue-500 p-4 rounded-lg mb-4">
        <Link to="/" className="text-white text-xl font-bold">Home</Link>
        <Link to="/forum" className="text-white text-xl">Forums</Link>
      </nav>

      {/* Forum Content */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-semibold text-gray-800">{forum.title}</h1>
        <p className="mt-2 text-gray-600 text-lg">{forum.description}</p>
        <div className="mt-6 text-gray-700">
          {/* Render the content with embedded images */}
          {renderContentWithImages(forum.content)}
        </div>
      </div>
    </div>
  );
};

export default OfficialForumPage;
