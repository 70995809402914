import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { Link } from 'react-router-dom';

const AdminPage = () => {
  const [forums, setForums] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingForum, setEditingForum] = useState(null);
  const [selectedTable, setSelectedTable] = useState('forums'); // Default table

  // Fetch forums from the selected table
  useEffect(() => {
    const fetchForums = async () => {
      setLoading(true);
      const { data, error } = await supabase.from(selectedTable).select('*');
      if (error) {
        console.error(error);
      } else {
        setForums(data);
      }
      setLoading(false);
    };

    fetchForums();
  }, [selectedTable]); // Refetch when the selected table changes

  const handleForumDelete = async (forumId) => {
    const { error } = await supabase.from(selectedTable).delete().eq('id', forumId);
    if (error) {
      console.error(error);
    } else {
      setForums(forums.filter((forum) => forum.id !== forumId));
    }
  };

  const handleForumEdit = (forum) => {
    setEditingForum(forum);
  };

  const handleForumUpdate = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase
      .from(selectedTable)
      .update({
        title: editingForum.title,
        content: editingForum.content,
      })
      .eq('id', editingForum.id);

    if (error) {
      console.error(error);
      return;
    }

    setForums(
      forums.map((forum) => (forum.id === editingForum.id ? data[0] : forum))
    );
    setEditingForum(null);
  };

  const handleForumChange = (e) => {
    const { name, value } = e.target;
    setEditingForum({ ...editingForum, [name]: value });
  };

  const totalActiveForums = forums.filter((forum) => forum.content !== '').length;

  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <h1 className="text-2xl font-semibold text-gray-800">Admin Dashboard</h1>

      {/* Table Selector */}
      <div className="mt-6">
        <label className="block text-gray-700 font-semibold">Select Table:</label>
        <select
          value={selectedTable}
          onChange={(e) => setSelectedTable(e.target.value)}
          className="mt-2 px-4 py-2 border rounded-lg"
        >
          <option value="forums">forums</option>
          <option value="official">official</option>
        </select>
      </div>

      {/* Statistics Section */}
      <div className="mt-6 p-6 bg-white border rounded-lg shadow-md">
        <h2 className="text-xl font-semibold">Forum Statistics</h2>
        <p>Total Forums: {forums.length}</p>
        <p>Active Forums: {totalActiveForums}</p>
      </div>

      {/* Forum List */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold">Forum List</h2>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <ul className="mt-4">
            {forums.map((forum) => (
              <li key={forum.id} className="mb-4 p-6 bg-white border rounded-lg shadow-md">
                {editingForum && editingForum.id === forum.id ? (
                  <form onSubmit={handleForumUpdate}>
                    <input
                      type="text"
                      name="title"
                      value={editingForum.title}
                      onChange={handleForumChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <textarea
                      name="content"
                      value={editingForum.content}
                      onChange={handleForumChange}
                      className="mt-4 w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                      type="submit"
                      className="mt-4 w-full px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                    >
                      Update Forum
                    </button>
                  </form>
                ) : (
                  <>
                    <h3 className="text-lg font-semibold">{forum.title}</h3>
                    <p>{forum.content}</p>
                    <div className="mt-2">
                      <button
                        onClick={() => handleForumEdit(forum)}
                        className="mr-2 text-blue-600 hover:underline"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleForumDelete(forum.id)}
                        className="text-red-600 hover:underline"
                      >
                        Delete
                      </button>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Link to create new forum */}
      <Link
        to="/create-forum"
        className="mt-6 w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
      >
        Create New Forum
      </Link>
    </div>
  );
};

export default AdminPage;
